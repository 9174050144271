import React from 'react'

import './signup.scss'

function Signup () {
  return (
    <>This is Signup</>
  )
}

export default Signup
