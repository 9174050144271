import React from 'react'
import { Link } from 'react-router-dom'
import Currency from "react-currency-formatter";

import './Location.scss'


class Location extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true,
      minified: this.props.minified || false
    }
  }



  render() {
    return (
      <div className={`location-card${this.state.minified ? ' minified' : ''}`}>
        <div className='location-card-inner'>
          <div className='location-image' style={this.props.image && { backgroundImage: `url(${this.props.image})` }}></div>
          <div className='location-content'>
            <div className='location-row location-head'>
              <h4 className='location-name'>{this.props.name}</h4>
              <span>{this.props.address}</span>
            </div>
            <div className='location-row location-statistics'>
              <div className='location-column'>
                <div className='column-key'>הזמנות החודש</div>
                <div className='column-value'>{this.props.orders}</div>
              </div>
              <div className='location-column-separator'></div>
              <div className='location-column'>
                <div className='column-key'>הוצאות החודש</div>
                <div className='column-value'>
                  <Currency
                    quantity={this.props.expanses > 0 && this.props.expanses * 1.17}
                    currency="ILS"
                    decimal='.'
                    group=","
                  />
                </div>
              </div>
            </div>
            <div className='location-row location-go'>עבור לסניף</div>
          </div>
        </div>
      </div>
    )
  }
}
export default Location