import React from 'react'

import './Loader.scss'

class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true
    }
  }
  render() {
    return (
      <div className={`loader ${this.props.theme ? this.props.theme : ''}`}>
        <svg className='spinner' viewBox='0 0 50 50'>
          <circle
            className='path'
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='3'
          ></circle>
        </svg>
      </div>
    )
  }
}
export default Loader