import React from 'react';
import { PDFDownloadLink, Page, Image, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment'

Font.register({
  family: 'Heebo', fonts: [
    { src: '../fonts/Heebo-Regular.ttf' }, // font-style: normal, font-weight: normal
    { src: '../fonts/Heebo-Bold.ttf', fontWeight: 700 },
  ]
});

// Create styles
const styles = StyleSheet.create({
  header: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  headerText: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 9
  },
  logo: {
    width: 48,
    height: 48,
    marginLeft: 20,
  },
  badatz: {
    width: 90,
    height: 48,
    position: 'absolute',
    top: 5,
    left: 5,
    textAlign: 'left'
  },
  page: {
    fontFamily: 'Heebo',
    fontSize: 9,
    textAlign: 'right',
    flexDirection: 'column',
    padding: 30,
  },
  title: {
    fontSize: 20,
    marginTop: 24,
    marginBottom: 40,
    textAlign: 'center',
  },
  table: {

  },
  flex: {
    display: "flex",
    alignContent:"space-between",
  },
  thead: {
    borderBottomWidth: 1,
    borderBottomColor: '#36554d',
    padding: 4,
  },
  tbody: {
    borderBottomWidth: 1,
    borderBottomColor: '#beccd8',
    padding: 4,
  },
  tfoot: {
    // backgroundColor: '#f0f0f0',
    padding: 4,
  },
  row: {
    flexDirection: 'row-reverse',
  },
  rowA: {
    width: '40%',
  },
  rowB: {
    width: '20%',
  },
  rowC: {
    width: '20%',
  },
  price: {
    fontFamily: 'Heebo'
  },
  signature: {
    marginTop: 16,
    marginBottom: 16,
    flexDirection: 'column',
  },
  pagination: {
    marginTop: 16,
    flexDirection: 'column',
  },
  textBold: {
    fontWeight: 'bold',
  },
  ils: {
    marginTop: 14,
    alignSelf: 'center',
  }
});

export function MyDocument(props) {
  // understand if the order was shipped
  // if it was shipped, display shipped items
  // if it was not shipped, display original order
  let getProducts;
  let total = 0;
  if (!props.order.shipped) {
    getProducts = props.order.products
    total = props.order.total
  } else {
    getProducts = props.order.delivered
    props.order.delivered.map((product, id) => {
      total += product.price * product.amount
    })
  }




  console.log('getproducts', getProducts)

  return (<Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src='../lyon.png' style={styles.logo} />
        <View style={styles.headerText}>
          <Text style={styles.textBold}>{props.order._id} תעודת משלוח מספר</Text>
          <Text>{`קפה ליון, סניף ${props.order.location.name}`}</Text>
          <Text>{`${props.order.location.address}`}</Text>
          <Text>{`${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')} :תאריך הנפקה`}</Text>
        </View>
      </View>
      <Image src='../badatz.jpeg' style={styles.badatz} />
      <View style={styles.title} fixed>
        <Text>תעודת משלוח</Text>
      </View>
      <View style={styles.table}>
        <View style={[styles.row, styles.thead]} fixed>
          <Text style={styles.rowA}>מוצר</Text>
          <Text style={styles.rowB}>כמות</Text>
          <Text style={styles.rowB}>מחיר</Text>
          <Text style={styles.rowC}>סך הכל ללא מע"מ</Text>
        </View>
        {getProducts.map((product, id) => {
          return (
            <View style={[styles.row, styles.tbody]}>
              <Text style={styles.rowA}>{product.name}</Text>
              <Text style={styles.rowB}>{product.amount}</Text>
              <Text style={styles.rowB}><Image style={styles.ils} src='../ils.png' />{(product.price).toFixed(2)}</Text>
              <Text style={styles.rowB}><Image style={styles.ils} src='../ils.png' />{(product.amount * product.price).toFixed(2)}</Text>
            </View>
          )
        })
        }
        <View style={[styles.row, styles.tfoot]}>
          <Text style={styles.rowA}></Text>
          <Text style={styles.rowB}></Text>
          <Text style={styles.rowB}></Text>
          <Text style={styles.rowB}></Text>
        </View>
        <View style={[styles.row, styles.tfoot, styles.textBold]}>
          <Text style={styles.rowA}></Text>
          <Text style={styles.rowB}>{props.test}</Text>
          <Text style={styles.rowB}>סך הכל כולל מע"מ</Text>
          <Text style={[styles.rowC, styles.price]}><Image style={styles.ils} src='../ils.png' />{(total * 1.17).toFixed(2)}</Text>
        </View>
        <View style={[styles.row, styles.tfoot]}>
          <Text style={styles.rowA}></Text>
          <Text style={styles.rowB}>{props.test}</Text>
          <Text style={styles.rowB}>מע"מ )%71(</Text>
          <Text style={[styles.rowC, styles.price]}><Image style={styles.ils} src='../ils.png' />{(total * 0.17).toFixed(2)}</Text>
        </View>
        <View style={[styles.row, styles.tfoot]}>
          <Text style={styles.rowA}></Text>
          <Text style={styles.rowB}>{props.test}</Text>
          <Text style={styles.rowB}>סך הכל</Text>
          <Text style={[styles.rowC, styles.price]}><Image style={styles.ils} src='../ils.png' />{(total).toFixed(2)}</Text>
        </View>

      </View>
      <View style={styles.signature}>
        <Text>חתימת הלקוח: ________________________</Text>
      </View>
      <View style={styles.pagination}>
        <Text render={({ pageNumber, totalPages }) => (
          `עמוד ${pageNumber} מתוך ${totalPages}`
        )} fixed />
        <Text>Orderly הונפק באמצעות</Text>
      </View>

    </Page>
  </Document>
  )
};

const OrderPdf = {
  helper1: <MyDocument />,
}

export default OrderPdf;