import React from 'react'
// import { Link } from 'react-router-dom'
// import EmptyList from '../../assets/illustrations/empty-list.svg'
// import Cart from '../../assets/icons/icon-cart.svg'

function Empty (props) {
  return (
    <div className='empty-list'>
      {/* <img className='cart' src={Cart} alt='' /> */}
      <h1>זה הזמן להוסיף מוצרים!</h1>
      <h4>שכחת משהו? אתה תמיד יכול להיזכר בהזדמנות הקודמות שלך</h4>
      {/* <Link to='/'>
        <div className='cancelOrder'>בטל הזמנה</div>
      </Link> */}
      {/* <img src={EmptyList} alt='' /> */}
    </div>
  )
}
export default Empty
