import React from "react";
import axios from "axios";
import "./welcome.scss";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import Location from "../../components/Location/Location";
import Ganhair from '../../assets/locations/ganhair.png'
import Star from '../../assets/locations/star.png'
import Yavne from '../../assets/locations/yavne.png'
import Big from '../../assets/locations/big.png'
import Oryeuda from '../../assets/locations/oryeuda.png'
import Asuta from '../../assets/locations/asuta.png'
import Badabum from '../../assets/locations/badabum.png'
import Jerusalem from '../../assets/locations/jerusalem.png'
import Placeholder from '../../assets/locations/placeholder.png'

class Locations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: { location: {} },
      isLoading: false
    };
  }

  getImageById = (id) => {
    switch (id) {
      case 1:
        return Star
      case 2:
        return Ganhair
      case 3:
        return Big
      case 4:
        return Asuta
      case 5:
        return Yavne
      case 6:
        return Oryeuda
      case 8:
        return Badabum
      case 10:
        return Jerusalem
      default:
        return Placeholder
    }
  }

  componentDidMount() {
    this.loadUser();
  }

  activateUser = () => {
    this.setState({ isLoading: true })
    const query = {
      activated: true,
    }
    axios
      .patch("/api/user/me/activate", query)
      .then(res => {
        if (res.data.success) {
          setTimeout(() => {
            this.props.history.push('/orders')
          }, 800)
        }

      })
      .catch(err => {
        console.log('user me', err);
        this.setState({ isLoading: false })
      });
  }

  loadUser = () => {
    // return who the user is
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user
        this.setState({ user: user }, () => {
        })

      })
      .catch(err => {
      });
  }


  render() {
    return (
      <>
        <div className='welcome'>
          <div className='welcome-inner'>
            <div className='welcome-message'>
              <h1>היי {this.state.user.name}, ברוך הבא</h1>
              <p>Orderly היא מערכת חכמה לביצוע הזמנות מספקים בפשטות וביעילות.</p>
            </div>
            <div className='welcome-animate'>
              <div className='your-location'>
                <Location
                  key={this.state.user.location._id}
                  id={this.state.user.location._id}
                  name={this.state.user.location.name}
                  image={this.getImageById(this.state.user.location._id)}
                  address={this.state.user.location.address}
                  expanses={0}
                  orders={0}

                />
              </div>
              <div className='now-you-can'>
                {/* <div className='user'>
                  <div className='avatar'>{this.state.user.email && this.state.user.email.slice(0, 2).toUpperCase()}</div>{this.state.user.name}
                </div> */}
                <h4>כמנהל סניף, מעכשיו אתה יכול:</h4>
                <ul className='check-list'>
                  <li>ליצור הזמנות חדשות</li>
                  <li>לקבל עידכונים מהספק</li>
                  <li>לקבל העתק של הזמנות במייל</li>
                  <li>לעקוב אחרי הוצאות הסניף</li>
                </ul>
                <Button
                  disabled={this.state.isLoading}
                  onClick={() => this.activateUser()}
                  className='button button-full-width'>
                  {this.state.isLoading ? <Loader /> : 'נשמע טוב? המשך לסניף שלך'}
                </Button>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }

}

export default Locations;
