import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import "./supplier.scss";
import Moment from "react-moment";
import Currency from "react-currency-formatter";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Lyonwhite from "../../assets/lyonwhite.png";
import Button from "../../components/Button/Button";
import { Mixpanel } from '../../utils/Mixpanel';

class Supplier extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        location: {}
      },
      locations: [],
      orders: [],
      openOrders: 0,
      shipped: false,
      isLoading: true
    };
  }

  componentDidMount() {
    // if (this.props.match.path.split('/')[2] === "all") {
    //   this.setState({ shipped: true })
    // }
    this.loadUser();
  }

  loadUser = () => {
    // return who the user is
    // save user details to state
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user

        // Send insights
        Mixpanel.identify(user.id);
        Mixpanel.people.set({
          $first_name: user.name,
          $email: user.email,
          role: user.role,
          $location: user.location ? user.location.name : ''
        });
        Mixpanel.track('View Supplier Page');


        this.setState({ user: user }, () => {
          // if (user.role == "owner" || user.role == 'supplier') {
          // this.loadLocations();
          // }
          // Now, load all the relevant orders
          this.loadOrders();
        })

      })
      .catch(err => {
        console.log('user me', err);
      });
  }

  calculateOpenOrders = (orders) => {
    let total = 0;
    orders.forEach((order, id) => {
      if (!order.shipped) total++
    })
    return total
  }

  loadOrders = (locId) => {
    // recieve all possible orders
    const locationId = "all"
    
    let paramsQuery = {}
    paramsQuery["supplier"] = true

    axios
      .get(`/api/orders`, { params: paramsQuery })
      .then(res => {
        setTimeout(
          function () {
            // Save all orders to state
            this.setState({
              orders: res.data.orders,
              openOrders: this.calculateOpenOrders(res.data.orders),
              isLoading: false
            });
          }.bind(this),
          400
        )
      })
      .catch(err => {
        console.log('bad', err);
      });
  };

  approveOrder = (order, e) => {
    const classlist = e.target.classList
    classlist.add('loading');

    console.log(order)

    const updateWith = {
      shipped: true,
      delivered: order.products
    }
    // console.log(updateWith)

    axios
      .patch(`/api/orders/${order._id}`, { shipped: true })
      .then(res => {
        // classlist.remove('loading')
        this.loadOrders()
      })
      .catch(err => {
        console.log('bad', err);
        // classlist.remove('loading')
      });


  }

  render() {
    return (
      <>
        <Header user={this.state.user} />
        <Row>

          <Col lg={12}>

            <div id="supplier">

              <div className='pageOrientation'>
                <div className="pageTitle">
                  <h2 className="title">{this.state.openOrders} הזמנות פתוחות</h2>
                  <p>לחץ על הזמנה כדי להציג את הפירוט שלה, לשנות כמויות או להמיר אותה לתעודת משלוח</p>
                </div>
                <div className="pageActions">
                  <Link to="supplier/all"><Button className="button button-ghost button-ghost-green">הצג היסטוריית תעודות</Button></Link>
                </div>
              </div>

              <div className='upcoming-orders'>
                {
                  this.state.orders.map((order, id) => {
                    return (order.shipped === this.state.shipped &&
                      <div key={order._id} className='upcoming-order'>
                        <Link className='upcoming-order-inner' tabIndex='0' to={`/order/${order._id}`}>
                          <div className='upcoming-order-border'>
                            <div className='company'><img src={Lyonwhite} alt='' /></div>
                            <div className='title'>הזמנה מס. {order._id}</div>
                            <div className=''>{order.amount} מוצרים</div>
                            <div className=''>
                              <Currency
                                quantity={order.total * 1.17}
                                currency="ILS"
                                decimal="."
                                group=","
                              />
                            </div>
                            <div className='timestamp'>
                              {order.location ? order.location.name : 'סניף לא ידוע'},&nbsp;
                              <Moment title={new Date(order.timestamp)} fromNow>
                                {order.timestamp}
                              </Moment>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>

            </div>
          </Col>
        </Row >
      </>
    );
  }

}

export default Supplier;
