import React from "react";
import axios from "axios";
import { Row, Col } from "react-flexbox-grid";
import "../../../styles/orderPrint.scss";
import "./order.scss";
import Moment from "react-moment";
import Currency from "react-currency-formatter";
// import Loader from "../../../components/Loader/Loader";
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import Modal from '../../../components/Modal/Modal';
import StickyBox from "react-sticky-box";
import { MyDocument } from "./orderpdf";
import { Mixpanel } from '../../../utils/Mixpanel';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';



class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      error: 0,
      shippingOrder: false,
      user: {
        location: {}
      },
      review: false, // when on, the interface is changing 
      order: {
        _id: 0,
        products: []
      },
      orders: [],
      delivered: [],
      isLoading: true,
      notShipped: [],
      nextOrder: {},
      prevOrder: {}
    };
  }

  componentDidMount() {
    // Firstly, let's get info about the user
    this.loadUser();
  }

  componentWillMount() {
    // this.unlisten = this.props.history.listen((history, action) => {
    //   if (action == "PUSH") {
    //     // const orderId = this.props.history.pathname.split('/')[2]
    //     // this.setState({ ...this.state.order, [_id]: orderId })
    //     // this.loadOrder(history.pathname.split('/')[2]);
    //   }
    // });
  }

  componentWillUnmount() {
    // this.unlisten();
  }

  showModal = e => {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  updateOrder = () => {
    // this.showModal()
    // return
    const orderId = this.state.order._id
    console.log('will update order', orderId, 'with the following', this.state.delivered)

    const query = {
      delivered: this.state.delivered,
      shipped: true
    }

    axios
      .patch(`/api/orders/${orderId}`, query)
      .then(res => {
        // classlist.remove('loading')
        const waait = new Promise(resolve => setTimeout(resolve, 800));
        waait.then(() => {
          this.setState({ shippingOrder: false })
          this.setState({ review: false })
          this.loadOrder(this.state.order._id)
          this.loadOrders()
        })


      })
      .catch(err => {
        console.log('bad', err);
        // classlist.remove('loading')
      })
      .finally(() => {

      });
  }

  loadOrders = () => {
    axios
      .get("/api/orders")
      .then(res => {
        this.setState({ orders: res.data.orders }, () => {
          // console.log(res.data.orders.find(x => x._id === this.props.history.pathname.split('/')[2]));

          // @TODO : organize this
          // this gets the next and the previous not-shipped orders
          let nextOrder, prevOrder, currentOrderIndex
          let notShippedArr = []
          this.state.orders.map((order, index) => {
            if (!order.shipped) {
              notShippedArr.push(order)
            }
          })
          currentOrderIndex = this.findItemIndexByKeyAndValue(notShippedArr, '_id', this.state.order._id)
          // Get the next/prev order
          // If none exist (if at the end or the begining,) LOOP IT!
          prevOrder = notShippedArr[currentOrderIndex - 1] || notShippedArr[notShippedArr.length - 1]
          nextOrder = notShippedArr[currentOrderIndex + 1] || notShippedArr[0]
          this.setState({
            notShipped: notShippedArr,
            prevOrder: prevOrder,
            nextOrder: nextOrder
          })

        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadOrder = (orderId) => {

    // load an order by a specified ID
    axios
      .get(`/api/orders/${orderId}`)
      .then(res => {

        // If no matching order found, return and set the error code state
        if (res.status === 204) {
          return this.setState({ error: 204 })
        }
        // If the order exists add it to the state
        this.setState({ error: -1, order: res.data.order[0] }, () => {
          this.setState({
            delivered: JSON.parse(JSON.stringify(res.data.order[0].products))
          });
        })

      })
      .catch(err => {
        //for now, let's treat 500 error just as 204
        // if (err.response.status === 500) {
        //   return this.setState({ error: 500 })
        // }
        console.log(err.response);
      });
  }

  findItemByKeyAndValue(array, key, value) {
    return array.find(x => x[key] === value)
  }

  findItemIndexByKeyAndValue(array, key, value) {
    return array.findIndex(x => x[key] === value)
  }

  resetDelivered = () => {
    this.setState({
      review: false,
      delivered: JSON.parse(JSON.stringify(this.state.order.products))
    })
  }

  getDeliveredTotal = () => {
    let total = 0
    this.state.order.delivered.map((product, id) => {
      total += product.price * product.amount
    })
    return total
  }

  getAmount = (product) => {
    let build
    let amount = {
      ordered: null,
      delivered: null
    }
    if (this.state.order.shipped) {

      // order was shipped
      if (this.findItemByKeyAndValue(this.state.order.delivered, '_id', product._id).amount !== product.amount) {
        // amount is different
        amount.ordered = product.amount
        amount.delivered = this.findItemByKeyAndValue(this.state.order.delivered, '_id', product._id).amount
        build = <><span className='delivered-amount status status-pending'>{amount.delivered}</span><span className='ordered-amount'>{amount.ordered}</span></>
      } else {
        // amount is the same
        amount.ordered = product.amount
        amount.delivered = this.findItemByKeyAndValue(this.state.order.delivered, '_id', product._id).amount
        build = <>{<span className='deliveredsd-amount'>{amount.delivered}</span>}</>
      }
    } else {
      // order was not shipped
      amount.ordered = product.amount
      build = <>{amount.ordered}</>
    }
    // console.log('asd', amount)
    return build
  }
  updateDelivered = (productId, amount) => {

    if (amount < 0)
      return

    this.setState(prevState => {
      const { delivered } = prevState;
      delivered[this.findItemIndexByKeyAndValue(this.state.delivered, '_id', productId)]['amount'] = amount;
      return { delivered };
    });

  }

  reviewOrder = (index) => {
    // Process the supplier changes in the order
    // Then, let the server know about these changes
    // Change 'Delivered' from false to true

    // alert(index);
    this.setState({ review: true })

  }

  loadUser = () => {
    // understand who the user is
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user

        // Send insights
        Mixpanel.identify(user.id);
        Mixpanel.track('View Order Page');
        Mixpanel.people.set({
          $id: user.id,
          $first_name: user.name,
          $email: user.email,
          $permission: user.$permission,
          $location: user.location ? user.location.name : ''
        });

        // add the user details to the state
        this.setState({ user: user }, () => {
          //Now, let's set up for brining the requested order
          const orderId = this.props.match.params.orderId;
          this.loadOrder(orderId);
          this.loadOrders();
        })

      })
      .catch(err => {
        console.log('user me', err);
      });
  }

  render() {
    return (
      <>
        <Modal onClose={this.showModal} show={this.state.showModal}>
          <div className='pdf-document'>
            <PDFDownloadLink document={<MyDocument order={this.state.order} />} fileName={`תעודת משלוח ${this.state.order._id}.pdf`}>
              {({ blob, url, loading, error }) => (loading ? <span>טוען מסמך...</span> : <Button>הורד כPDF</Button>)}
            </PDFDownloadLink>
            <PDFViewer>
              <MyDocument order={this.state.order} />
            </PDFViewer>
          </div>
        </Modal>
        <Header user={this.state.user} />
        <Row>
          <Col xs={12} lg={4}>
            {this.state.error < 0 && (
              this.state.order.timestamp && (
                <StickyBox offsetTop={32} offsetBottom={32}>
                  <div className={`order-extra-wrapper ${this.state.review ? 'reviewing' : ''}`}>
                    <div className='order-extra-inner'>

                      <div className="order-extra-details">
                        <ul className='pagination-wrapper'>
                          {/* <li><a title='להזמנה הקודמת' href={`/order/${this.state.prevOrder && this.state.prevOrder._id}`}><Icon kind="icon_arrow_right" /></a></li> */}
                          {/* <li className='pagination'>{this.findItemIndexByKeyAndValue(this.state.notShipped, '_id', this.state.order._id) + 1}/{this.state.notShipped.length}</li> */}
                          {/* <li><a title='להזמנה הבאה' href={`/order/${this.state.nextOrder && this.state.nextOrder._id}`}><Icon kind="icon_arrow_left" /></a></li> */}
                        </ul>
                        {/* <Dropdown> */}
                        <h2 title={this.state.order._id}>הזמנה מס. {this.state.order._id}</h2>

                        {/* <div className='dropdown-menu'>
                            <ul className='dropdown-menu-items'>
                              {
                                this.state.orders.map(order => (
                                  !order.shipped && <li className='dropdown-item' value={order._id} key={order._id}><a href={`/order/${order._id}`}>הזמנה מס. {order._id}</a></li>
                                ))
                              }
                            </ul>
                          </div>
                        </Dropdown> */}

                        {/* <ul>
                        <li><a href="#"><Icon kind="icon_print" /></a></li>
                        <li><a href="#"><Icon kind="icon_download" /></a></li>
                      </ul> */}
                        <hr />
                        <table>
                          <thead>
                            <tr>
                              <td colSpan="2"><h5>פרטים נוספים</h5></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>מספר הזמנה</td>
                              <td><a href={`/order/${this.state.order._id}`}>{this.state.order._id}</a></td>
                            </tr>
                            <tr>
                              <td className="no-border">סטטוס</td>
                              <td className="no-border">
                                {
                                  (this.state.order.shipped) ? (
                                    <div className='status status-approved'>ההזמנה אושרה</div>
                                  ) : (
                                      <div className='status status-pending'>ממתינה לאישור מהספק</div>
                                    )
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>סניף</td>
                              <td>{this.state.order.location.name}</td>
                            </tr>
                            <tr>
                              <td>נשלח על ידי</td>
                              <td>{this.state.order.createdBy.name}</td>
                            </tr>
                            <tr>
                              <td className="no-border">תאריך ושעה</td>
                              <td className="no-border"><Moment format="DD/MM/YYYY HH:mm:ss">{this.state.order.timestamp}</Moment></td>
                            </tr>

                            {

                              // (this.state.order.shipped) ? (
                              //   <tr>
                              //     <td className="no-border">תעודת משלוח הונפקה?</td>
                              //     <td className="no-border">כן</td>
                              //   </tr>
                              // ) : (
                              //     <tr>
                              //       <td className="no-border">תעודת משלוח הונפקה?</td>
                              //       <td className="no-border">כן</td>
                              //     </tr>
                              //   )
                            }
                          </tbody>
                        </table>
                        <div className='supplierActions'>
                          {this.state.user.role === "supplier" && (
                            (!this.state.order.shipped) && (
                              <Button
                                tabIndex="0"
                                className="button button-full-width"
                                onClick={() => this.reviewOrder(this.state.order._id)}>המר לתעודת משלוח</Button>
                            )
                          )}



                          <Button
                            tabIndex="0"
                            className="button button-light button-full-width"
                            onClick={() => this.showModal()}>גירסת הדפסה</Button>
                        </div>
                      </div>
                      <div className='review-order'>
                        <h2>וודא כמויות</h2>
                        <p>באפשרותך לערוך כמויות של מוצרים על ידי לחיצה על + \ -</p>
                        <div className='supplierActions'>
                          <Button
                            tabIndex="0"
                            className="button button-yellow button-full-width"
                            disabled={this.state.shippingOrder}
                            onClick={() => {
                              this.setState({ shippingOrder: true })
                              this.updateOrder()
                            }}>
                            {/* {onClick={() => this.updateOrder()}>} */}
                            {this.state.shippingOrder ? <Loader theme='light' /> : 'סיימת? אשר ושלח!'}
                          </Button>
                          <Button
                            tabIndex="0"
                            className="button button-link button-full-width"
                            disabled={this.state.shippingOrder}
                            onClick={() => {
                              this.resetDelivered()
                            }}>
                            ביטול
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </StickyBox>
              )
            )
            }
          </Col>

          <Col xs={12} lg={8}>

            {(this.state.error === 204 || this.state.error === 500) &&
              (<>שגיאה {this.state.error}: ההזמנה המבוקשת לא נמצאה</>)
            }

            {this.state.error < 0 && (
              this.state.order.timestamp && (
                <div id="viewOrder" className={`${this.state.order.shipped ? 'order-shipped' : 'order-pending'} ${this.state.shippingOrder ? 'shipping' : ''}`}>
                  <div className="products-list">
                    {

                      (!this.state.review) ? (
                        <table className="table table-orders">
                          <thead>
                            <tr>
                              <th>מק"ט</th>
                              <th>פריט</th>
                              <th>מחיר ליח'</th>
                              <th>כמות</th>
                              <th>סך הכל</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.order.products.map((product, id) => {
                              return (
                                <tr key={id}>
                                  <td className="orderId">
                                    {product._id}
                                  </td>
                                  <td className="productname">{product.name}<div className='category'>{product.category}</div></td>
                                  <td>
                                    <Currency
                                      quantity={product.price}
                                      currency="ILS"
                                      decimal="."
                                      group=","
                                    />
                                  </td>
                                  <td className='amount'>{this.getAmount(product)}</td>
                                  <td>
                                    <span>
                                      <Currency
                                        quantity={product.price * ((this.state.order.shipped) ? this.findItemByKeyAndValue(this.state.order.delivered, '_id', product._id).amount : product.amount)}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className="tfoot-total">
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>סך הכל כולל מע"מ</td>
                              <td>
                                <span>
                                  {
                                    this.state.order.shipped ?
                                      new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.getDeliveredTotal() * 1.17)
                                      :
                                      new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.state.order.total * 1.17)
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>מע"מ (17%)</td>
                              <td>
                                {
                                  this.state.order.shipped ?
                                    new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.getDeliveredTotal() * 0.17)
                                    :
                                    new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.state.order.total * 0.17)
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>סך הכל</td>
                              <td>
                                {
                                  this.state.order.shipped ?
                                    new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.getDeliveredTotal())
                                    :
                                    new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' }).format(this.state.order.total)
                                }
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      ) : (
                          <table className="table table-orders table-editing">
                            <thead>
                              <tr>
                                <th>מק"ט</th>
                                <th>פריט</th>
                                <th>כמות מבוקשת</th>
                                <th>כמות שתסופק</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.order.products.map((product, id) => {
                                return (
                                  <tr key={id}>
                                    <td className="orderId">
                                      {product._id}
                                    </td>
                                    <td className="productname"><div>{product.name}</div><div className='category'>{product.category}</div></td>
                                    <td>
                                      <span>{product.amount}</span>
                                    </td>
                                    <td>
                                      <div className='amount'>
                                        <div className='changeAmount'>
                                          <div className="product-increase" onClick={() => this.updateDelivered(product._id, this.findItemByKeyAndValue(this.state.delivered, '_id', product._id).amount + 1)}></div>
                                          <span className={`${(this.findItemByKeyAndValue(this.state.delivered, '_id', product._id).amount !== product.amount) ? 'status status-pending' : ''}`}>{this.state.delivered.length > 0 && this.findItemByKeyAndValue(this.state.delivered, '_id', product._id).amount}</span>
                                          <div className="product-decrease" onClick={() => this.updateDelivered(product._id, this.findItemByKeyAndValue(this.state.delivered, '_id', product._id).amount - 1)}></div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )
                    }
                  </div>
                </div>
              )
            )}
          </Col>

        </Row >
      </>
    );
  }
}

export default Order;
