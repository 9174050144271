import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

export default function Survey({ user, show }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const openSurvey = () => {
    const query = {
      surveyDone: true,
    };
    Axios
      .patch('/api/user/me/survey', query)
      .then((res) => {
        if (res.data.success) {
          setShowModal(false)
          setTimeout(() => {
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSfnLK8nD7xmvWIiZhd1PtChgslUvXuXPhdJ3kJJwpxtnuVYUQ/viewform',
              '_blank'
            );
          }, 400);
        }
      })
      .catch((err) => {
        console.log('user me', err);
      });
  };

  return (
    <Modal onClose={setShowModal} show={showModal} disableDismiss={true}>
      <h2>היי {user?.name},</h2>
      <p>בתקופה הקרובה מערכת Orderly הולכת לעבור מספר שינויים ושידרוגים.</p>
      <p>
        נשמח אם תוכלו להשקיע מספר דקות מזמנכם כדי להביע את חוות דעתכם.
        <br />
        תשובותיכם יעזרו לנו להבין את אופי השימוש שלכם במערכת ובכך לשפר את חווית
        המשתמש לרמה המיטבית ביותר - ולחסוך לכם זמן וכסף.
      </p>
      <p>
        💰 כאות תודה, השימוש במערכת בחודש יוני 2022 יהיה ללא עלות! (ציינו את
        הסניף שלכם בסיום)
        <br />⏱ משך השאלון עשוי לארוך בין 3 ל-5 דקות בלבד.
      </p>
      <div style={{ textAlign: 'left', marginTop: 24 }}>
        <Button onClick={openSurvey} tabIndex="1" className="button">
          התחל שאלון
        </Button>
        {/* <a href="/" tabIndex="2" className="button button-light">
      אולי אחר כך
    </a> */}
      </div>
    </Modal>
  );
}
