import React from 'react'
import { Link } from "react-router-dom";
import axios from 'axios'
import { Row, Col } from "react-flexbox-grid";
import './success.scss'
import { saveAs } from 'file-saver';
import Moment from "react-moment";
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import { Mixpanel } from '../../../utils/Mixpanel';

class NewOrderSuccess extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      order: {},
      emailSent: false,
      sendingEmail: false
    };
  }
  sendEmail = () => {
    const orderId = this.props.match.params.orderId;

    // const waait = new Promise(resolve => setTimeout(resolve, 200000));
    // waait.then(() => {
    //   this.setState({ sendingEmail: false })
    // })

    axios
      .get(`/api/orders/${orderId}/email`)
      .then(res => {
        this.setState({ order: res.data.order[0] })
        this.setState({ sendingEmail: false })
      })
      .catch(err => {
        console.log(err);
      });
  }

  createAndDownloadPdf = () => {

  }

  loadOrderDetails = () => {
    const orderId = this.props.match.params.orderId;
    axios
      .get(`/api/orders/${orderId}`)
      .then(res => {
        const order = res.data.order[0]

        // Send insights
        Mixpanel.track('Viewed Success Page');

        this.setState({ order: order })
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadOrderDetails();
  }

  render() {
    return (
      <>
        <Row>
          <Col lg={4} />
          <Col lg={4}>
            <div className='order-success' ref={el => (this.printRef = el)}>
              <h1>ההזמנה נשלחה לספק!</h1>
              <div className="success-container" >
                <div className='order-summary'>

                  <div className='summary-row'>
                    <div className='row-title'>מספר הזמנה</div>
                    <div className='row-value'><a target="_blank" rel="noopener noreferrer" href={`/order/${this.state.order._id}`}>{this.state.order._id}</a></div>
                  </div>
                  <div className='summary-row'>
                    <div className='row-title'>סניף</div>
                    <div className='row-value'>{this.state.order.location && this.state.order.location.name}</div>
                  </div>
                  <div className='summary-row'>
                    <div className='row-title'>מתי נשלחה</div>
                    <div className='row-value'>
                      <Moment title={new Date(this.state.order.timestamp)} fromNow>
                        {this.state.order.timestamp}
                      </Moment>
                    </div>
                  </div>
                  <div className='summary-row'>
                    <div className='row-title'>נשלחה על ידי</div>
                    <div className='row-value'>{this.state.order.createdBy && this.state.order.createdBy.name}</div>
                  </div>

                  <div className='summary-actions'>
                    <Link to={`/order/${this.state.order._id}`}><Button className='button button-ghost-dark'>הצג הזמנה</Button></Link>

                    <Button
                      className='button button-ghost-dark'
                      disabled={this.state.sendingEmail}
                      onClick={() => {
                        this.setState({ sendingEmail: true })
                        this.sendEmail()
                      }}>
                      {this.state.sendingEmail ? <Loader /> : 'שלח לי במייל'}
                    </Button>
                  </div>

                </div>
              </div>
              <div className='actions-footer'>
                <Link to='/'><Button>חזור למסך הראשי</Button></Link>
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }

}

export default NewOrderSuccess