import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import jwtDecode from "jwt-decode";
// user roles
import Supplier from './pages/supplier/supplier'
import SupplierAll from './pages/supplier/all/all'
import Orders from './pages/orders/orders'
import Locations from './pages/locations/locations'
import ProductsNew from './pages/products/new/new'
import Welcome from './pages/welcome/welcome'
import Order from './pages/orders/order/order'
import OrdersSuccess from './pages/orders/success/success'
import newOrder from './pages/newOrder/newOrder'
import Login from './pages/login/login'
import Signup from './pages/signup/signup'
import setAuthorizationToken from './utils/setAuthorizationToken'
import './styles/App.scss'
// import axios from 'axios';

export default function App() {
  return (


    <div className='App'>

      {/* <Router basename='ordersapp'>  */}
      <Router basename='/'>
        <Switch>
          <HomeRoute Route path='/' exact></HomeRoute>
          <Route path='/login' component={Login} />
          <Route path='/logout' component={Logout} />
          <Route path='/signup' component={Signup} />
          <OwnerRoute path='/products/new' component={ProductsNew} />
          <PrivateRoute path='/welcome' component={Welcome} />
          <PrivateRoute path='/locations' component={Locations} />
          <PrivateRoute path='/location/:locationId/orders' component={Orders} />
          <PrivateRoute path='/supplier' exact component={Supplier} />
          <PrivateRoute path='/supplier/all' exact component={SupplierAll} />
          <LocationRoute path='/orders' exact component={Orders} />
          <PrivateRoute path='/order/:orderId' exact component={Order} />
          <PrivateRoute path='/orders/success/:orderId' component={OrdersSuccess} />
          <PrivateRoute path='/orders/new' component={newOrder} />
          {/* <Redirect to='/' /> */}
        </Switch>
      </Router>
      <div className='grad'></div>

    </div>
  )
}

const checkAuth = {
  isAuthenticated: false,
  authenticate(role) {
    const token = localStorage.getItem('orderly-jwt')
    if (token) {
      // send a get request to the server to check if the user has still access 
      setAuthorizationToken(token)
      // axios
      //   .get('http://localhost:5000/api/user/me')
      //   .then(res => {
      //     console.log('checkalive', res.data.user)
      //     checkAuth.isAuthenticated = true;
      //     console.log('returning tru zoon')
      //     return true
      //   })
      //   .catch(err => {
      //     console.error('checkalive', err);
      //   });


      return true
    } else {
      return false
    }
  },
  signout(cb) {
    checkAuth.isAuthenticated = false;
  }
};

function PrivateRoute({ children, ...rest }) {
  // const jwt = jwtDecode(localStorage.getItem('orderly-jwt'))
  // if (!jwt) {
  //   this.setState({
  //     user: null
  //   })
  //   return
  // }

  // axios
  //   .get('/api/users/login', { headers: { Authorization: getJwt() } })
  //   .then(res => {
  //     console.log('hello??', res.data)
  //     this.setState({
  //       user: res.data
  //     })
  //   })
  return (
    checkAuth.authenticate() ? (
      <Route {...rest} render={({ location }) => children} />
    ) : (
        <Redirect to={'/login?redirect=' + window.location.href} />
      )
  );
}
function OwnerRoute({ children, ...rest }) {
  return (
    checkAuth.authenticate("owner") ? (
      // console.log('kmate')
      <Route {...rest} render={({ location }) => children} />
    ) : (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      )
  );
}

function LocationRoute({ children, ...rest }) {
  return (
    checkAuth.authenticate("location") ? (
      // console.log('kmate')
      <Route {...rest} render={({ location }) => children} />
    ) : (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      )
  );
}

function HomeRoute({ children, ...rest }) {
  // Understand who the user is
  // Set the home route
  // Take him there

  if (!localStorage.getItem('orderly-jwt')) {
    return (<Redirect
      to={{
        pathname: "/login"
      }}
    />)
  }

  const jwt = jwtDecode(localStorage.getItem('orderly-jwt'))

  if (!jwt) {
    return (<Redirect
      to={{
        pathname: "/login"
      }}
    />)
  }

  // axios
  //   .get('/api/users/login', { headers: { Authorization: getJwt() } })
  //   .then(res => {
  //     console.log('hello??', res.data)
  //     this.setState({
  //       user: res.data
  //     })
  //   })



  if (jwt.role == 'location') {
    return (<Redirect
      to={{
        pathname: "/orders"
      }}
    />)
  } else if (jwt.role == 'owner') {
    return (<Redirect
      to={{
        pathname: "/locations"
      }}
    />)
  } else if (jwt.role == 'supplier') {
    return (<Redirect
      to={{
        pathname: "/supplier"
      }}
    />)
  } else {
    return (<Redirect
      to={{
        pathname: "/login"
      }}
    />)
  }



}

function Logout(props) {
  localStorage.removeItem('orderly-jwt')
  window.location.replace("/login");
}