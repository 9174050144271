import axios from 'axios'

export default function setAuthorizationToken(token) {
    // console.log('tokeeeen',token)
    axios.interceptors.response.use(function (response) { return response }, function (error) {
    if (error.response.status === 401) {
      window.location.replace("/login");
    }
    return Promise.reject(error);
  });

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}
