import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link, Redirect } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import LoaderB from "../../components/LoaderB/LoaderB";
import "./newOrder.scss";
import "../../components/NewOrderPanel/NewOrderPanel.scss";
import "../../components/NewOrderProduct/NewOrderProduct.scss";
import "../../components/NewOrderTemplate/NewOrderTemplate.scss";
import "../../components/NewOrderActions/NewOrderActions.scss";
import axios from "axios";
import Icon from "../../components/Icon/Icon";
import Empty from "./Empty";
import Truck from "../../assets/icons/icon-truck-container.svg";
import Currency from "react-currency-formatter";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Moment from "react-moment";
import { Mixpanel } from '../../utils/Mixpanel';
import smartlookClient from 'smartlook-client'
import { Tabs } from "../../components/Tabs";
import { Item } from '@react-stately/collections';

const ProductActionsComponent = ({product, isInOrder, sumInOrder}) =>{
  return (
    <div className="productActions">
      {isInOrder ? (
        <div className="product-amount">
          {sumInOrder}
        </div>
      ) : (
        <div className="product-increase">
          <Icon kind="icon_plus" />
        </div>
      )}
    </div>
  );
};

class NewOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        location: {}
      },
      order: [],
      products: [],
      categories: [],
      selectedProduct: [],
      filterStr: "",
      totalPrice: 0,
      totalProducts: 0,
      isLoading: true,
      isSending: false,
      isSuccess: false,
      newOrderId: null,
      showMobileSummary: false,
      shownTab: 1
    };
  }
  
  
  CategoriesTabs = () => {
    if (!this.state.categories.length) {
      return null;
    }

    return (
      <div>
        <Tabs aria-label="קטגוריות פריטים">
          {this.state.categories?.map((category) => (
            <Item
              key={category._id}
              title={category._id}
            >
              {category.products?.map((product) => (
                <div
                  key={product._id}
                  className="product"
                  onClick={() => {
                    this.addOne(product);
                  }}
                  tabIndex="0"
                >
                  <div className="productDetails">
                    <div className="productName">{product.name}</div>
                    <div className="productPrice">
                      {category._id}
                    </div>
                  </div>
                  <ProductActionsComponent product={product} src="Mobile web" search={false} isInOrder={this.isProductInOrder(product._id) ? true : false} sumInOrder={this.sumProductInOrder(product._id)} />
                </div>
              ))}
            </Item>
          ))}
        </Tabs>
      </div>
    );
  };

  loadOrders = () => {

    axios
      .get("/api/products")
      .then(res => {
        setTimeout(
          function () {
            this.setState({ products: res.data.products, isLoading: false });
            this.setState({ categories: res.data.categories, isLoading: false });
            setTimeout(
              () => (this.productsSearch ? this.productsSearch.focus() : null),
              120
            );
          }.bind(this),
          400
        );
      })
      .catch(err => {
        // const { history } = this.props;
        // if (err.response.status === 401) {
        //   return history.push("/login")
        // }
        console.log(err);
      });
  };

  componentDidMount() {
    const newOrderStorage = JSON.parse(localStorage.getItem('orderly-new-order'))
    if (newOrderStorage && newOrderStorage.amount > 0) {
      this.setState({ order: newOrderStorage.products }, () => {
        this.updateTotalPrice()

      })
    }

    this.loadUser();
    this.loadOrders();
  }

  closeMobileSummary = () => {
    this.setState({ showMobileSummary: false })
  }

  isProductInOrder = (productId) => {
    return this.state.order.findIndex(x => x._id === productId) > -1
  }

  sumProductInOrder = (productId) => {
    // returns the amount of appearances of a product
    // within the current order
    // e.g 7 cheesecakes
    return this.state.order.find(x => x._id === productId)?.amount
  }

  getTotalOfProducts = (arr) => {
    let total = 0;
    arr.map((product, id) => {
      total += product.price * product.amount
    })
    return total
  }

  getAmountOfAProduct = (productId) => {
    let total = 0;
    total = this.state.order.find(x => x._id === productId) ? this.state.order.find(x => x._id === productId).amount : 0
    return total
  }

  getAmountOfProducts = (arr) => {
    let total = 0;
    arr.map((product, id) => {
      total += product.amount
    })
    return total
  }

  updateTotalPrice = () => {
    let total = 0;
    for (let i = 0, length = this.state.order.length; i < length; i++) {
      total += this.state.order[i]["price"] * this.state.order[i]["amount"];
    }
    this.setState({ totalPrice: total });
    // let newTotalPrice
    // newTotalPrice = this.state.order.map(product => console.log(product.price))
    // this.setState({
    //   totalPrice: newTotalPrice
    // })
  };

  loadUser = () => {
    // return who the user is
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user

        // Send insights
        Mixpanel.identify(user.id);
        Mixpanel.track('View New Order Page');
        Mixpanel.people.set({
          $id: user.id,
          $first_name: user.name,
          $email: user.email,
          role: user.role,
          $location: user.location ? user.location.name : ''
        });



        try {
          // Record
          smartlookClient.identify(user.id, {
            name: user.name,
            role: user.role,
            email: user.email,
            environment: process.env.NODE_ENV
          })
          smartlookClient.track('View New Order Page');
        }
        catch (error) {
          console.error(error);
        }

        this.setState({ user: user })

      })
      .catch(err => {
        console.log('user me', err);
      });
  }

  sendOrder = () => {
    // const { history } = this.props;
    this.setState({ isSending: true })

    const order = {
      products: this.state.order
    }

    axios
      .post("/api/orders", order)
      .then(res => {
        const newOrderId = res.data.order._id

        // Send insights
        Mixpanel.identify(this.state.user.id);
        Mixpanel.track('Created New Order');
        Mixpanel.people.set({
          $id: this.state.user.id,
          $first_name: this.state.user.name,
          $email: this.state.user.email,
          role: this.state.user.role,
          $location: this.state.user.location ? this.state.user.location.name : ''
        });

        localStorage.removeItem('orderly-new-order')
        setTimeout(() => (
          this.setState({ isSuccess: true, newOrderId: newOrderId }))
          , 2000);
      })
      .catch(err => console.log(err));
  };

  clearProduct = function (selectedItem) {

    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Clicked Clear Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });

    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];


    order.splice(order.findIndex(x => x._id === product._id), 1);

    this.saveOrder(order)


  }

  saveOrder = function (order) {
    this.setState({ order }, () => {
      let dataToLocal = {
        products: order,
        amount: this.getAmountOfProducts(order),
        total: this.getTotalOfProducts(order),
        timestamp: new Date()
      }
      localStorage.setItem('orderly-new-order', JSON.stringify(dataToLocal))
      this.updateTotalPrice();
    });
  }

  resetProduct = function (selectedItem) {

    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Clicked Reset Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });

    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];

    order.splice(
      order.findIndex(x => x._id === product._id),
      1
    );

    this.saveOrder(order);



  }

  removeProduct = function (selectedItem) {

    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Clicked Remove Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });

    // Get product details from the catalogue
    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];

    // check if this product is already in the order
    if (this.state.order.find(x => x._id === product._id)) {
      order.find(x => x._id === product._id).amount -= 1;
      if (order.find(x => x._id === product._id).amount === 0) {
        order.splice(
          order.findIndex(x => x._id === product._id),
          1
        );
      }
      this.setState({ order });
    } else {
      order.push({
        name: product.name,
        price: product.price,
        category: product.category,
        _id: product._id,
        amount: 1
      });
      this.setState({ order });
    }
    this.saveOrder(order);
  };

  // @TODO WIP
  changeAmount = {
    increaseByOne: function () {
      return 'increasing by 1'
    },
    decreaseByOne: function () {
      return 'decreasing by 1'
    },
    byValue: function () {
      return 'will set by given value'
    }
  }

  addOne = function (selectedItem) {
    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Changed Amount of Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });

    // Get product details from the catalogue
    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];

    // check if this product is already in the order
    if (!this.state.order.find(x => x._id === product._id)) {
      order.push({
        name: product.name,
        price: product.price,
        category: product.category,
        _id: product._id,
        amount: 1
      });
      this.saveOrder(order);
    } else {
      const productInOrder = this.state.order.find(x => x._id === product._id)
      this.changeProductAmount(selectedItem, productInOrder.amount + 1)
    }
  }

  changeProductAmount = function (selectedItem, val) {
    let newAmount = parseInt(val)

    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Changed Amount of Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });

    // Get product details from the catalogue
    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];

    // check if this product is already in the order
    if (this.state.order.find(x => x._id === product._id)) {

      if (this.amount && isNaN(newAmount))
        newAmount = parseInt(this.amount.value) + 1

      if (newAmount == null) {
        console.log('empty, should i add by one?')
      } else if (newAmount > 99) {
        return
      } else if (newAmount <= 0 || isNaN(newAmount)) {
        this.amount.value = newAmount = 1
      } else {
        this.amount.value = newAmount
      }

      order.find(x => x._id === product._id).amount = newAmount;

      this.saveOrder(order);
    } else {

      order.push({
        name: product.name,
        price: product.price,
        category: product.category,
        _id: product._id,
        amount: 1
      });
      this.saveOrder(order);
    }
  }

  addProduct = function (selectedItem) {

    // let orders = [...this.state.order, selectedItem]
    // // this.state.order.push(selectedItem)

    // this.setState({ order: orders }, () => {})

    // // console.log(asd)
    // return
    // Get product details from the catalogue

    // Send insights
    Mixpanel.identify(this.state.user.id);
    Mixpanel.track('Clicked Add Product');
    Mixpanel.people.set({
      $id: this.state.user.id,
      $first_name: this.state.user.name,
      $email: this.state.user.email,
      role: this.state.user.role,
      $location: this.state.user.location ? this.state.user.location.name : ''
    });


    const product = this.state.products.find(x => x._id === selectedItem._id);
    let order = [...this.state.order];

    // check if this product is already in the order
    if (this.state.order.find(x => x._id === product._id)) {
      order.find(x => x._id === product._id).amount += 1;
      this.saveOrder(order);
    } else {
      order.push({
        name: product.name,
        price: product.price,
        category: product.category,
        _id: product._id,
        amount: 1
      });
      this.saveOrder(order);
    }
  };



  render() {
    if (this.state.isSuccess) {
      // redirect to home if signed up
      return <Redirect to={{ pathname: `./success/${this.state.newOrderId}` }} />;
    }

    let allTabs = [
      {
        title: <>ההזמנה שלך {this.state.order.length > 0}</>,
        content:

          (!this.state.order.length) ? (
            <>
              <div className='empty-list'>
                <h1>ההזמנה שלך ריקה</h1>
                <h4>התחל להוסיף מוצרים</h4>
              </div>
              <div className='cta-search' onClick={() => this.setState({ shownTab: 1 })}>
                <Button className='button'>
                  <Icon kind="icon_search" size="24" />
                  חפש מוצר
                  </Button>
              </div>
            </>
          ) : (
              <>

                <div className="products-list-container">
                  {this.state.order.map((product, id) => (
                    <div
                      key={id}
                      className="product"
                      ref={e => {
                        this.state.selectedProduct[id] = e;
                      }}
                      // onClick={() => this.changeProductAmount(product)}
                      tabIndex="0"
                    >
                      <div className="productActions">
                        <div className="product-reset" onClick={() => this.resetProduct(product)}><Icon kind="icon_trash" /></div>
                      </div>
                      <div className="productDetails">
                        <div className="productName">{product.name}</div>
                        <div className="productPrice">
                          {product.category}
                        </div>
                      </div>
                      <div>
                        <input
                          type="tel"
                          className='amountText'
                          value={parseInt(product.amount)}
                          onChange={(e) => this.changeProductAmount(product, e.target.value)}
                          ref={input => { this.amount = input }}
                        />
                      </div>
                    </div>
                  ))}
                </div>

              </>
            )

      },
      {
        title: 'חיפוש',
        content:
          <>
            {/* <h1>חיפוש</h1> */}
            <div className="products-search">
              <div className={`input-search ${this.state.filterStr ? 'hasValue' : ''}`}>
                <input
                  autoComplete="off"
                  disabled={this.state.isLoading ? "disabled" : ""}
                  ref={input => { this.productsSearchMobile = input }}
                  name="products-search"
                  type="text"
                  placeholder="חפש מוצר לפי שם"
                  onChange={e =>
                    this.setState({ filterStr: e.target.value })
                  }
                  value={this.state.filterStr}
                />
                {
                  (this.state.filterStr) && (
                    <div className='clear' onClick={() => { this.setState({ filterStr: '' }, this.productsSearch.focus()) }}><Icon kind="icon_times" size="12" /></div>
                  )
                }
              </div>
            </div>
            {this.state.filterStr &&(
              <div className="products-list-title">
                <h6>
                  {this.state.products.filter(item =>
                      item.name.includes(this.state.filterStr)
                    ).length > 0
                      ? "תוצאות חיפוש"
                      : "לא נמצאו תוצאות"}
                </h6>
              </div>
            )}
            <div className="products-list-container">
              {this.state.filterStr
                ? this.state.products
                  .filter(item =>
                    item.name.includes(this.state.filterStr)
                  )
                  .slice(0, 20)
                  .map((product, id) => (
                    <div
                      key={id}
                      className="product"
                      ref={e => {
                        this.state.selectedProduct[id] = e;
                      }}
                      onClick={() => { this.addOne(product) }}
                      tabIndex="0"
                    >
                      <div className="productDetails">
                        <div className="productName">{product.name}</div>
                        <div className="productPrice">
                          {product.category}
                        </div>
                      </div>
                      <ProductActionsComponent product={product} src="Mobile web" search={true} isInOrder={this.isProductInOrder(product._id) ? true : false} sumInOrder={this.sumProductInOrder(product._id)} />
                    </div>
                  ))
                : 
                  <this.CategoriesTabs />
                }
              {this.state.isLoading ? <Loader /> : ""}
            </div>
          </>
      }
    ]
    return (
      <>
        <Header user={this.state.user} />
        {/* <Link to='/orders' className='BackToOrders'>
          <div className='newOrderHeader'>
            <Icon kind='icon_arrow_right' size='24' />
            <div>הזמנות</div>
          </div >
        </Link> */}

        <div id="newOrder" className={this.state.isSending ? 'isSending' : ''}>
          <Row>
            <Col lg={4} lgOffset={0}>
              <div className={`mobile-order-summary ${this.state.showMobileSummary ? 'shown' : ''}`}>
                <div className='mobile-order-summary-inner'>
                  <div className='summary-controls'>
                    <div className='back-to-order' onClick={() => this.closeMobileSummary()}>
                      <Icon kind='icon_arrow_right' />
                      <span>חיפוש</span>
                    </div>
                    {(this.state.order.length > 0) && (
                      <div className='send-order' onClick={() => this.sendOrder()}>
                        שלח הזמנה
                  </div>
                    )}
                  </div>
                  {(!this.state.order.length) ? (
                    <>
                      <div className='empty-list'>
                        <h1>ההזמנה שלך ריקה</h1>
                        <h4>חזור אחורה כדי להוסיף מוצרים</h4>
                        <p><span className='closeSummary' onClick={() => this.closeMobileSummary()}>הוסף מוצרים להזמנה</span></p>
                      </div>
                    </>)
                    : (
                      <>
                        <div className='summary-title'>
                          <h1>סיכום הזמנה</h1>
                          {/* <p>צריך זמן לחשוב על זה? אתה יכול לחזור להזמנה שלך בכל עת - ההזמנה שלך נשמרת באופן אוטומטי.</p> */}
                        </div>
                        <div className='items-wrapper'>
                          {this.state.order.map((product, id) => {
                            // const productD = this.state.products.find(
                            //   x => x._id === product._id
                            // )

                            return (
                              <div className='item' key={id}>
                                <div className='item-right'>
                                  <div className="productActions">
                                    <div className="product-reset" onClick={() => this.resetProduct(product)}><Icon kind="icon_trash" /></div>
                                  </div>
                                  <div className="productname">{product.name}<div className='category'>{product.category}</div></div>
                                </div>
                                <div className="amount">

                                  <input
                                    type="tel"
                                    className='amountText'
                                    value={parseInt(product.amount)}
                                    onChange={(e) => this.changeProductAmount(product, e.target.value)}
                                    onFocus={(e) => e.target.select()}
                                    ref={input => { this.amount = input }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                </div>
                <div className='gradCover'></div>

              </div>

              <div className='mobile-tabs' ref={e => { this.state.tabs = e; }}>
                {/* <ul>
                  {allTabs.map((item, id) => (
                    <li
                      onClick={() => this.setState({ shownTab: id })}
                      className={id === this.state.shownTab && 'shown'} key={id}>
                      {item.title}
                    </li>
                  ))}
                </ul> */}

                {/* <ul>
                  <li className='active'>ההזמנה שלך</li>
                  <li className='' onClick={(e) => e.target.classList.add('asdad')}>חיפוש</li>
                </ul> */}
                {allTabs.map((item, id) => (
                  <div key={id} className={`mobile-tab-content ${id == this.state.shownTab ? 'shown' : ''}`}>
                    <div className="mobile-tab-content-inner">
                      {item.content}
                    </div>
                  </div>
                ))}





              </div>

              {(this.state.order.length > 0) && (
                <div className='mobile-order-summary-cta' onClick={() => this.setState({ showMobileSummary: true })}>
                  <div className='mobile-order-summary-cta-inner'>

                    <div>
                      <Icon kind='icon_basket' />
                      {this.getAmountOfProducts(this.state.order)} פריטים
                          </div>
                    <div>
                      <strong>(<Currency quantity={this.state.totalPrice * 1.17} currency="ILS" decimal="." group="," />)</strong>
                    </div>
                  </div>
                </div>
              )}

              <div className="products-container">
                <div className="products-search">
                  <div className={`input-search ${this.state.filterStr ? 'hasValue' : ''}`}>
                    <input
                      autoComplete="off"
                      disabled={this.state.isLoading ? "disabled" : ""}
                      ref={input => { this.productsSearch = input }}
                      name="products-search"
                      type="text"
                      placeholder="חפש מוצר לפי שם"
                      onChange={e =>
                        this.setState({ filterStr: e.target.value })
                      }
                      value={this.state.filterStr}
                      onKeyDown={e => {
                        const key = e.which;
                        if (key === 40 || key === 13) {
                          const filteredList = this.state.products.filter(
                            item => item.name.includes(this.state.filterStr)
                          );
                          // arrow key down
                          e.preventDefault();
                          if (filteredList.length > 0) {
                            this.state.selectedProduct[0].focus();
                          }
                          console.log("Pressed Arrow Down");
                        }
                      }}
                    />
                    {
                      (this.state.filterStr) && (
                        <div className='clear' onClick={() => { this.setState({ filterStr: '' }, this.productsSearch.focus()) }}><Icon kind="icon_times" size="12" /></div>
                      )
                    }
                  </div>
                </div>
                <div className="products-list-title">
                  <h6>
                    {!this.state.filterStr
                      ? "מוצרים שממש אוהבים אצלכם:"
                      : this.state.products.filter(item =>
                        item.name.includes(this.state.filterStr)
                      ).length > 0
                        ? "תוצאות חיפוש"
                        : "לא נמצאו תוצאות"}
                  </h6>
                </div>
                <div className="products-list-container">
                  {this.state.filterStr
                    ? this.state.products
                      .filter(item =>
                        item.name.includes(this.state.filterStr)
                      )
                      .map((product, id) => (
                        <div
                          key={id}
                          className="product"
                          ref={e => {
                            this.state.selectedProduct[id] = e;
                          }}
                          onClick={() => this.addOne(product)}
                          onKeyDown={e => {
                            const key = e.which;
                            if (key === 13) {
                              this.addOne(product)
                            } else if (key === 38) {
                              // arrow key up
                              e.preventDefault();
                              if (id - 1 >= 0) {
                                this.state.selectedProduct[id - 1].focus();
                              } else {
                                this.productsSearch.focus();
                              }
                            } else if (key === 40) {
                              const filteredList = this.state.products.filter(
                                item =>
                                  item.name.includes(this.state.filterStr)
                              );
                              // arrow key down
                              e.preventDefault();
                              if (id + 1 < filteredList.length) {
                                this.state.selectedProduct[id + 1].focus();
                              } else {
                                this.state.selectedProduct[0].focus();
                              }
                            } else if (
                              (key >= 65 && key <= 90) ||
                              (key >= 48 && key <= 57)
                            ) {
                              // when typing A-Z
                              this.productsSearch.focus();
                            }
                          }}
                          tabIndex="0"
                        >
                          <div className="productDetails">
                            <div className="productName">{product.name}</div>
                            <div className="productPrice">
                              {product.category}
                            </div>
                          </div>
                          <ProductActionsComponent product={product} src="Web" search={true} isInOrder={this.isProductInOrder(product._id) ? true : false} sumInOrder={this.sumProductInOrder(product._id)} />
                        </div>
                      ))
                    : this.state.products.slice(0, 5).map((product, id) => (
                      <div
                        key={id}
                        className="product"
                        onClick={() => this.addOne(product)}
                        ref={e => {
                          this.state.selectedProduct[id] = e;
                        }}
                        onKeyDown={e => {
                          const key = e.which;
                          if (key === 13) {
                            this.addOne(product);
                          } else if (key === 38) {
                            // arrow key up
                            e.preventDefault();
                            if (id - 1 >= 0) {
                              this.state.selectedProduct[id - 1].focus();
                            } else {
                              this.productsSearch.focus();
                            }
                          } else if (key === 40) {
                            // arrow key down
                            e.preventDefault();
                            if (id + 1 < this.state.selectedProduct.length) {
                              this.state.selectedProduct[id + 1].focus();
                            } else {
                              this.state.selectedProduct[0].focus();
                            }
                          } else if (
                            (key >= 65 && key <= 90) ||
                            (key >= 48 && key <= 57)
                          ) {
                            // when typing A-Z
                            this.productsSearch.focus();
                          }
                        }}
                        tabIndex="0"
                      >
                        <div className="productDetails">
                          <div className="productName">{product.name}</div>
                          <div className="productPrice">
                            {product.category}
                          </div>
                        </div>
                        <ProductActionsComponent product={product} src="Web" search={false} isInOrder={this.isProductInOrder(product._id) ? true : false} sumInOrder={this.sumProductInOrder(product._id)} />
                        {/* <div className='productId'>מק"ט {product._id.replace(/\D+/g, '').slice(0, 8)}</div> */}
                      </div>
                    ))}

                  {this.state.isLoading ? <Loader /> : ""}
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="user-order-container">
                {!this.state.order.length ? (
                  <Empty />
                ) : (
                    <>
                      <div className="newOrderTemplate">
                        <div className="newOrderHead">
                          <h2>ההזמנה שלך</h2>
                          <div className="time"><Moment format="יום dddd D MMMM, YYYY">{new Date()}</Moment></div>
                        </div>
                        <div className="products-list">
                          <table className="table table-orders">
                            <thead>
                              <tr>
                                <th>מק"ט</th>
                                <th>פריט</th>
                                <th>מחיר</th>
                                <th>כמות</th>
                                <th colSpan="2">סך הכל</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.order.map((product, id) => {
                                // const productD = this.state.products.find(
                                //   x => x._id === product._id
                                // )

                                return (
                                  <tr key={id}>
                                    <td className="productId" title={product._id}>
                                      {product._id}
                                    </td>
                                    <td className="productname">{product.name}<div className='category'>{product.category}</div></td>
                                    <td>
                                      <Currency
                                        quantity={product.price}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    </td>
                                    <td>
                                      <div className="amount">
                                        <div className="changeAmount">
                                          <div
                                            className="product-increase"
                                            onClick={() => this.changeProductAmount(product, parseInt(product.amount) + 1)}
                                          ></div>
                                          <input
                                            type="text"
                                            className='amountText'
                                            value={product.amount}
                                            onChange={(e) => this.changeProductAmount(product, e.target.value)}
                                            onKeyDown={e => {
                                              const key = e.which;
                                              // e.preventDefault();
                                              if (key === 13) {
                                                this.productsSearch.focus()
                                              }
                                              if (key === 40) {
                                                // arrow key up, increase item by 1
                                                this.changeProductAmount(product, parseInt(e.target.value) - 1)
                                              }
                                              if (key === 38) {
                                                // arrow key down, decrease item by 1
                                                this.changeProductAmount(product, parseInt(e.target.value) + 1)
                                              }
                                            }}
                                            ref={input => { this.amount = input }}
                                          />

                                          <div
                                            className="product-decrease"
                                            onClick={() => this.removeProduct(product)}
                                          ></div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span>
                                        <strong>
                                          <Currency
                                            quantity={product.price * product.amount}
                                            currency="ILS"
                                            decimal="."
                                            group=","
                                          />
                                        </strong>
                                      </span>
                                    </td>
                                    <td className='productRemove'>
                                      <div onClick={() => this.clearProduct(product, -1)}>
                                        <Icon kind="icon_trash" />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr className='summary'>
                                <td className="productId"></td>
                                <td className="client"></td>
                                <td></td>
                                <td className="amount">סך הכל כולל מע"מ</td>
                                <td colSpan="2" className='big'>
                                  <span>
                                    <strong>
                                      <Currency
                                        quantity={this.state.totalPrice * 1.17}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    </strong>
                                  </span>
                                </td>
                              </tr>
                              <tr className='summary'>
                                <td className="productId"></td>
                                <td className="client"></td>
                                <td></td>
                                <td className="amount">מע"מ (17%)</td>
                                <td colSpan="2">
                                  <span>
                                    <strong>
                                      <Currency
                                        quantity={this.state.totalPrice * 0.17}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    </strong>
                                  </span>
                                </td>
                              </tr>
                              <tr className='summary'>
                                <td className="productId"></td>
                                <td className="client"></td>
                                <td></td>
                                <td className="amount">סך הכל</td>
                                <td colSpan="2">
                                  <span>
                                    <strong>
                                      <Currency
                                        quantity={this.state.totalPrice}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    </strong>
                                  </span>
                                </td>
                              </tr>


                            </tfoot>
                          </table>
                        </div>

                      </div>
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </div>
        {
          this.state.order.length && !this.state.isSending ? (
            <div className="order-actions-container">
              <div className="order-actions">
                <div className="summary">
                  <span>{this.getAmountOfProducts(this.state.order)} פריטים</span> בהזמנה שלך
              </div>
                <div>

                  <Button
                    tabIndex="0"
                    className="button button-yellow"
                    disabled={this.state.shippingOrder}
                    onClick={() => this.sendOrder()}>
                    שלח הזמנה
                </Button>

                </div>
              </div>
            </div>
          ) : null
        }
        <Link to="/orders">
          <div className="close">סגור</div>
        </Link>
        <div className="pattern"></div>
        {this.state.isSending ? <LoaderB /> : ''}
      </>
    );
  }

}



export default NewOrder;
