import React from 'react'
import { Link } from 'react-router-dom'
import Currency from "react-currency-formatter";

import './Button.scss'


class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true,
      minified: this.props.minified || false
    }
  }

  render() {
    return (
      <button className={`button${this.state.minified ? ' minified' : ''}`} {...this.props}>
        {this.props.children}
      </button>
    )
  }
}
export default Button