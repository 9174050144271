import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import "./all.scss";
import Moment from "react-moment";
import Currency from "react-currency-formatter";
import Loader from "../../../components/Loader/Loader";
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";

class Supplier extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        location: {}
      },
      locations: [],
      orders: [],
      openOrders: 0,
      shipped: false,
      isLoading: true
    };
  }

  componentDidMount() {
    // if (this.props.match.path.split('/')[2] === "all") {
    //   this.setState({ shipped: true })
    // }
    this.loadUser();
  }

  loadUser = () => {
    // return who the user is
    // save user details to state
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user
        this.setState({ user: user }, () => {
          // if (user.role == "owner" || user.role == 'supplier') {
          // this.loadLocations();
          // }
          // Now, load all the relevant orders
          this.loadOrders();
        })

      })
      .catch(err => {
        console.log('user me', err);
      });
  }

  calculateOpenOrders = (orders) => {
    let total = 0;
    orders.forEach((order, id) => {
      if (!order.shipped) total++
    })
    return total
  }

  loadOrders = (locId) => {
    // recieve all possible orders
    const locationId = "all"
    axios
      .get(`/api/orders`)
      .then(res => {
        setTimeout(
          function () {
            // Save all orders to state
            this.setState({
              orders: res.data.orders,
              openOrders: this.calculateOpenOrders(res.data.orders),
              isLoading: false
            });
          }.bind(this),
          400
        )
      })
      .catch(err => {
        console.log('bad', err);
      });
  };

  approveOrder = (order, e) => {
    const classlist = e.target.classList
    classlist.add('loading');

    console.log(order)

    const updateWith = {
      shipped: true,
      delivered: order.products
    }
    // console.log(updateWith)

    axios
      .patch(`/api/orders/${order._id}`, { shipped: true })
      .then(res => {
        // classlist.remove('loading')
        this.loadOrders()
      })
      .catch(err => {
        console.log('bad', err);
        // classlist.remove('loading')
      });


  }

  getAmount = (delivered) => {
    let amount = 0

    delivered.map(item => (
      amount += item.amount
    ))
    return amount
  }

  getTotal = (delivered) => {
    let total = 0
    delivered.map(item => (
      total += item.amount * item.price
    ))
    return total
  }

  render() {
    return (
      <>
        <Header user={this.state.user} />
        <Row>

          <Col lg={12}>
            <div className="pageTitle">
              <h2 className="title">היסטוריית הזמנות - {this.state.orders.length}</h2>
            </div>
            <div className="table-container">
              <div className="table-actions">
                {this.state.isLoading ? (this.state.isLoading ? <Loader /> : "") : (
                  <table className="table table-orders">
                    <thead>
                      <tr>
                        <th>הזמנה</th>
                        <th>סניף</th>
                        <th>מתי נשלחה</th>
                        <th>כמות מוצרים</th>
                        <th>סכום</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.orders.map((order, id) => (
                          (order.shipped) && (
                            <tr key={id} >
                              <td className="orderId">
                                <Link to={`/order/${order._id}`}>הזמנה מס. {order._id}</Link>
                                {
                                  (order.shipped) ?
                                    (
                                      <div className='status status-approved'>ההזמנה אושרה</div>
                                    ) :
                                    (
                                      <div className='status status-pending'>ממתינה לאישור מהספק</div>
                                    )
                                }
                              </td>
                              <td>{order.location.name}</td>
                              <td>
                                <Moment title={new Date(order.timestamp)} fromNow>
                                  {order.timestamp}
                                </Moment>
                              </td>
                              <td>
                                {
                                  (order.shipped) ?
                                    (
                                      this.getAmount(order.delivered)
                                    ) :
                                    (
                                      order.amount
                                    )
                                }
                              </td>
                              <td>
                                {
                                  (order.shipped) ?
                                    (
                                      <Currency
                                        quantity={this.getTotal(order.delivered) * 1.17}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    ) :
                                    (
                                      <Currency
                                        quantity={order.total * 1.17}
                                        currency="ILS"
                                        decimal="."
                                        group=","
                                      />
                                    )
                                }
                              </td>
                              <td>
                              </td>
                            </tr>

                          )
                        ))
                      }
                    </tbody>
                  </table>
                )}
              </div>
            </div>


          </Col>
        </Row>
      </>
    )
  }

}

export default Supplier;
