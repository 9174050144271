import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import './locations.scss';
import Moment from 'react-moment';
import Currency from 'react-currency-formatter';
import Loader from '../../components/Loader/Loader';
import Location from '../../components/Location/Location';
import Header from '../../components/Header/Header';
// import Cafelyon from '../../assets/lyon.png';
import Ganhair from '../../assets/locations/ganhair.png';
import Star from '../../assets/locations/star.png';
import Yavne from '../../assets/locations/yavne.png';
import Big from '../../assets/locations/big.png';
import Oryeuda from '../../assets/locations/oryeuda.png';
import Asuta from '../../assets/locations/asuta.png';
import Badabum from '../../assets/locations/badabum.png';
import Jerusalem from '../../assets/locations/jerusalem.png';
import Ashkelon from '../../assets/locations/ashkelon.png';
import { Mixpanel } from '../../utils/Mixpanel';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import Survey from '../../components/Survey/Survey';

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        location: {},
      },
      locations: [],
      filterStr: '',
      filterLocation: null,
      filterMonth: null,
      orders: [],
      range: [],
      isLoading: true,
      showModal: false,
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  getImageById = (id) => {
    switch (id) {
      case 1:
        return Star;
      case 2:
        return Ganhair;
      case 3:
        return Big;
      case 4:
        return Asuta;
      case 5:
        return Yavne;
      case 6:
        return Oryeuda;
      case 8:
        return Badabum;
      case 10:
        return Jerusalem;
      case 11:
        return Ashkelon;
      default:
        return Big;
    }
  };

  loadUser = () => {
    // return who the user is

    axios
      .get('/api/user/me')
      .then((res) => {
        let user = res.data.user;

        // Send insights
        Mixpanel.identify(user.id);
        Mixpanel.track('View Locations Page');
        Mixpanel.people.set({
          $id: user.id,
          $first_name: user.name,
          $email: user.email,
          role: user.role,
          $location: user.location ? user.location.name : '',
        });

        this.setState({ user: user }, () => {
          if (!user.surveyDone) {
            this.showModal();
          }
          this.loadOrders();
          this.loadLocations();
        });
      })
      .catch((err) => {
        console.log('user me', err);
      });
  };

  showModal = (e) => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  loadOrders = () => {
    // build up the filters
    let paramsQuery = {};
    if (this.filterLocation.value)
      paramsQuery['location'] = this.filterLocation.value;
    if (this.filterMonth && this.filterMonth.value)
      paramsQuery['year'] = new Date(this.filterMonth.value).getFullYear();
    if (this.filterMonth && this.filterMonth.value)
      paramsQuery['month'] = new Date(this.filterMonth.value).getMonth();

    this.setState({ orders: [], isLoading: true });
    axios
      .get(`/api/orders`, { params: paramsQuery })
      .then((res) => {
        setTimeout(
          function () {
            this.setState({
              orders: res.data.orders,
              range: res.data.range[0],
              isLoading: false,
            });
          }.bind(this),
          400
        );
      })
      .catch((err) => {
        console.log('bad', err);
      });
  };

  getAmount = (delivered) => {
    let amount = 0;

    delivered.map((item) => (amount += item.amount));
    return amount;
  };

  getTotal = (delivered) => {
    let total = 0;
    delivered.map((item) => (total += item.amount * item.price));
    return total;
  };

  loadLocations = () => {
    const userId = this.state.user._id;
    axios
      .get('/api/locations', { params: { user: this.state.user } })
      .then((res) => {
        setTimeout(
          function () {
            this.setState({
              locations: res.data.locations,
              isLoading: false,
            });
          }.bind(this),
          400
        );
      })

      .catch((err) => {
        console.log('bad', err);
      });
  };

  getOrdersPerLocation = (location, orders) => {
    const locationOrders = orders.filter(
      (order) => order.location._id == location._id
    );
    return locationOrders?.length;
  };

  calculateOrdersPerLocation = (location, orders) => {
    const locationOrders = orders.filter(
      (order) => order.location._id == location._id
    );

    const initialValue = 0;
    const sumWithInitial = locationOrders.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      initialValue
    );

    return sumWithInitial;
  };

  getMonthString = (int) => {
    switch (int) {
      case 0:
        return 'ינואר';
        break;
      case 1:
        return 'פברואר';
        break;
      case 2:
        return 'מרץ';
        break;
      case 3:
        return 'אפריל';
        break;
      case 4:
        return 'מאי';
        break;
      case 5:
        return 'יוני';
        break;
      case 6:
        return 'יולי';
        break;
      case 7:
        return 'אוגוסט';
        break;
      case 8:
        return 'ספטמבר';
        break;
      case 9:
        return 'אוקטובר';
        break;
      case 10:
        return 'נובמבר';
        break;
      case 11:
        return 'דצמבר';
        break;

      default:
        return int;
        break;
    }
  };

  render() {
    return (
      <>
        <Header user={this.state.user} />
        <Row>
          <Col lg={1} />
          <Col lg={10}>
            {!this.state.isLoading && (
              <div className="pageTitle">
                <h2 className="title">{this.state.locations.length} סניפים</h2>
              </div>
            )}

            <div className="locations-container">
              {this.state.locations
                ? this.state.locations.map((location) => (
                    // location.orders.length > 0 && console.log(location.name, location.orders)
                    <div className="location-card-container" key={location._id}>
                      <Link to={`/location/${location._id}/orders`}>
                        <Location
                          key={location._id}
                          id={location._id}
                          name={location.name}
                          image={this.getImageById(location._id)}
                          address={location.address}
                          expanses={this.calculateOrdersPerLocation(
                            location,
                            this.state.orders
                          )}
                          orders={this.getOrdersPerLocation(
                            location,
                            this.state.orders
                          )}
                        />
                      </Link>
                    </div>
                  ))
                : null}
              {this.state.isLoading ? <Loader /> : ''}
            </div>
            <div className="pageTitle">
              <h2 className="title">הזמנות החודש</h2>
            </div>

            <div className="table-container">
              <div className="table-actions">
                <div className="table-search">
                  {this.state.user && this.state.user.role === 'location' && (
                    <div className="input-search">
                      <input
                        className="input-search"
                        type="text"
                        placeholder="חפש הזמנה"
                        onChange={(e) =>
                          this.setState({ filterStr: e.target.value })
                        }
                      />
                    </div>
                  )}
                </div>

                <select
                  defaultValue=""
                  onChange={(e) => this.loadOrders()}
                  ref={(input) => {
                    this.filterLocation = input;
                  }}
                >
                  <option value="">כל הסניפים</option>
                  {this.state.locations.map((location) => (
                    <option key={location._id} value={location._id}>
                      {location.name}
                    </option>
                  ))}
                </select>
                {this.state.range.dates && (
                  <select
                    onChange={(e) => this.loadOrders()}
                    defaultValue={
                      new Date(
                        this.state.range.dates[0].year,
                        this.state.range.dates[0].month - 1
                      )
                    }
                    ref={(input) => {
                      this.filterMonth = input;
                    }}
                  >
                    {this.state.range.dates.map((d) => {
                      return (
                        <option value={new Date(d.year, d.month - 1)}>
                          {this.getMonthString(d.month - 1)} {d.year} ({d.total}{' '}
                          הזמנות)
                        </option>
                      );
                    })}

                    <option value={null} disabled>
                      {this.state.range.total} הזמנות סך הכל
                    </option>
                  </select>
                )}
              </div>

              <table className="table table-orders">
                <thead>
                  <tr>
                    <th>הזמנה</th>
                    <th>סניף</th>
                    <th>מתי נשלחה</th>
                    <th>כמות מוצרים</th>
                    <th>סכום</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.map((order, id) => (
                    <tr key={id}>
                      <td className="orderId">
                        <Link to={`/order/${order._id}`}>
                          הזמנה מס. {order._id}
                        </Link>
                        {order.shipped ? (
                          <div className="status status-approved">
                            ההזמנה אושרה
                          </div>
                        ) : (
                          <div className="status status-pending">
                            ממתינה לאישור מהספק
                          </div>
                        )}
                      </td>
                      <td>
                        {order.location ? order.location.name : 'לא ידוע'}
                      </td>
                      <td>
                        <Moment title={new Date(order.timestamp)} format={'LL'}>
                          {order.timestamp}
                        </Moment>
                      </td>
                      <td>
                        {order.shipped
                          ? this.getAmount(order.delivered)
                          : order.amount}
                      </td>
                      <td>
                        {order.shipped ? (
                          <Currency
                            quantity={this.getTotal(order.delivered) * 1.17}
                            currency="ILS"
                            decimal="."
                            group=","
                          />
                        ) : (
                          <Currency
                            quantity={order.total * 1.17}
                            currency="ILS"
                            decimal="."
                            group=","
                          />
                        )}
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {this.state.isLoading ? <Loader /> : ''}
            </div>
          </Col>
        </Row>
        
        <Survey show={this.state.showModal} user={this.state.user} />
      </>
    );
  }
}

export default Locations;
