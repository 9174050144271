import React from 'react'
import axios from 'axios'
import './login.scss'
import setAuthorizationToken from '../../utils/setAuthorizationToken'
import Button from "../../components/Button/Button";
import Loader from '../../components/Loader/Loader';
import logo from '../../../src/assets/logo.svg'
import { Mixpanel } from '../../utils/Mixpanel';

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: null,
      isLoading: false
    }
  }

  getError(status) {
    switch (status) {
      case 401:
        return 'אחד או יותר מהפרטים שהזנת אינם נכונים'
      default:
        return 'אופס.. השרת לא מגיב. נסו לרענן את העמוד.'
    }
  }

  change(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  submit(e) {
    e.preventDefault()
    this.setState({ isLoading: true, error: null })
    axios
      .post('/api/users/login', {
        email: this.state.email.toLocaleLowerCase(),
        password: this.state.password
      })
      .then(res => {
        const { history } = this.props;
        const token = res.data.token
        const { user } = res.data

        // Send insights
        Mixpanel.identify(user._id);
        Mixpanel.track('Successful Login');
        Mixpanel.people.set({
          $id: user._id,
          $first_name: user.name,
          $email: user.email,
          role: user.role,
          $location: user.location ? user.location.name : ''
        });

        localStorage.setItem('orderly-jwt', token)
        setAuthorizationToken(token)
        setTimeout(
          function () {
            switch (res.data.user.role) {
              case "location":
                if (res.data.user.activated)
                  history.push("/orders")
                else
                  history.push("/welcome")
                break;
              case "supplier":
                history.push("/supplier")
                break;
              case "owner":
                history.push("/locations")
                break;
              default:
                history.push("/")
            }

          }, 800)

      })
      .catch((err) => {
        Mixpanel.track('Unsuccessful Login');
        setTimeout(
          function () {
            this.setState({
              error: err.response.status,
              isLoading: false
            })
          }.bind(this),
          400
        )
      })
  }

  componentDidMount() {
    // console.log(this)
    Mixpanel.track('Viewed Login Page');
    setTimeout(
      () => (this.email ? this.email.focus() : null),
      600
    );
  }

  render() {
    const { error } = this.state
    return (
      <div>
        <div className="login-container">
          <div className="login-wrapper">
            <div className="login-content">
              <img src={logo} className='App-logo' alt='logo' />

              <h1>התחבר לחשבון שלך</h1>
              {error && <p className='error'>{this.getError(error)}</p>}
              <form onSubmit={e => this.submit(e)}>
                <fieldset disabled={this.state.isLoading && 'disabled'}>
                  <div className='group'>
                    <input
                      required
                      type='text'
                      id="email"
                      name='email'
                      onChange={e => this.change(e)}
                      ref={input => { this.email = input }}
                    />
                    <label htmlFor="email">שם משתמש</label>
                  </div>
                  <div className='group'>
                    <input
                      required
                      type='password'
                      id="password" name='password'
                      onChange={e => this.change(e)}
                    />
                    <label htmlFor="password">סיסמא</label>
                  </div>
                  <Button className="button button-full-width" type='submit'>
                    {this.state.isLoading ? <Loader /> : 'התחבר'}
                  </Button>
                  <div className='forgotpassword'>שכחת את הסיסמא שלך?</div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>





      </div>
    )
  }
}
export default Login
