import React from 'react'
import { crisp } from './components/Crisp'
import ReactDOM from 'react-dom'
import App from './App'
import { Mixpanel } from './utils/Mixpanel'
import * as serviceWorker from './serviceWorker'
import smartlookClient from 'smartlook-client'

smartlookClient.init('afad2556b415fc036608770f1ac93eec7a4d4c06')

if (process.env.NODE_ENV === "production") {
  // hotjar.initialize(1666631, 6)
  // crisp.initialize('543f881e-e3ab-49e8-9287-b53bb6710748')
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
