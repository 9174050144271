import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import "./orders.scss";
import Moment from "react-moment";
import "moment/locale/he";
import Currency from "react-currency-formatter";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
// import Cafelyon from '../../assets/lyon.png';
import Lyonwhite from "../../assets/lyonwhite.png";
import Location from '../../components/Location/Location'
import Ganhair from '../../assets/locations/ganhair.png'
import Star from '../../assets/locations/star.png'
import Yavne from '../../assets/locations/yavne.png'
import Big from '../../assets/locations/big.png'
import Oryeuda from '../../assets/locations/oryeuda.png'
import Asuta from '../../assets/locations/asuta.png'
import Badabum from '../../assets/locations/badabum.png'
import Jerusalem from '../../assets/locations/jerusalem.png'
import StickyBox from "react-sticky-box";
import Button from "../../components/Button/Button";
import { Mixpanel } from '../../utils/Mixpanel';
import Survey from "../../components/Survey/Survey";

class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        location: {}
      },
      location: {
        orders: []
      },
      locations: [],
      currentLocation: null,
      filterStr: "",
      orders: [],
      isLoading: true,
      showModal: false
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  showModal = (e) => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };


  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (action == "POP")
        this.loadOrders(location.pathname.split('/')[2]);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  loadUser = () => {
    // understand who the user is
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user

        // Send insights
        Mixpanel.identify(user.id);
        Mixpanel.track('View Orders Page');
        Mixpanel.people.set({
          $id: user.id,
          $first_name: user.name,
          $email: user.email,
          role: user.role,
          $location: user.location ? user.location.name : ''
        });

        this.setState({ user: user }, () => {
          const locationId = this.props.match.params.locationId
          if (user.role == "owner") {
            return this.loadLocation(locationId)
          }
          if (user.role == "supplier") {
            return this.loadLocation("all")
          }
          if (!user.surveyDone) {
            this.showModal();
          }
          this.loadLocation(user.location._id)
        })

      })
      .catch(err => {
        console.log('user me', err);
      });
  }

  loadLocation = (id) => {
    axios
      .get(`/api/locations/${id}`)
      .then(res => {
        this.setState({
          location: res.data.location[0],
          isLoading: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  changeMonth = (month) => {
    this.setState({ orders: [], isLoading: true })
    const locationId = this.state.user.location && this.state.user.location._id || this.props.match.params.locationId
    axios
      .get(`/api/locations/${locationId}/orders`, { params: { month: 12, year: 2019 } })
      // .get("/api/orders/locations", { params: { locationId: locationId } })
      .then(res => {
        const { history } = this.props
        // history.push(`/location/${locationId}/orders`)
        this.loadLocation();

        setTimeout(
          function () {
            this.setState({
              orders: this.filterResults(res.data.orders),
              isLoading: false
            });
          }.bind(this),
          400
        )
      })
      .catch(err => {
        console.log('bad', err);
      });
  }

  loadLocations = () => {
    axios
      .get('/api/locations')
      .then(res => {
        this.setState({ locations: res.data.locations })
      })
      .catch(err => {
        console.log(err)
      })
  }

  changeLocation = (locationId) => {
    this.loadOrders(locationId)
  }

  loadOrders = (locId) => {
    // this.setState({ orders: [], isLoading: true })
    // const locationId = locId || this.state.user.location && this.state.user.location._id || this.props.match.params.locationId
    // axios
    //   .get(`/api/locations/${locationId}/orders`)
    //   // .get("/api/orders/locations", { params: { locationId: locationId } })
    //   .then(res => {
    //     const { history } = this.props
    //     // history.push(`/location/${locationId}/orders`)

    //     setTimeout(
    //       function () {
    //         this.setState({
    //           orders: this.filterResults(res.data.orders),
    //           isLoading: false
    //         });
    //       }.bind(this),
    //       400
    //     )
    //   })
    //   .catch(err => {
    //     console.log('bad', err);
    //   });
  };

  getMonthlyTotalPayments = () => {
    let total = 0;
    for (let i = 0, length = this.state.orders.length; i < length; i++) {
      total += this.state.orders[i]["total"];
    }
    return total;
  };

  filterResults = orders => {
    if (!orders) return []
    const filteredResults = orders.filter(item =>
      item.timestamp.includes(new Date().getFullYear() + new Date().getMonth())
    );
    return filteredResults;
  };

  countItems(items) {
    let amount = 0
    items.map((item) => { amount += item.amount })
    return amount
  }

  getAmount = (delivered) => {
    let amount = 0

    delivered.map(item => (
      amount += item.amount
    ))
    return amount
  }

  getTotal = (delivered) => {
    let total = 0
    delivered.map(item => (
      total += item.amount * item.price
    ))
    return total
  }

  getImageById = (id) => {

    switch (id) {
      case 1:
        return Star
      case 2:
        return Ganhair
      case 3:
        return Big
      case 4:
        return Asuta
      case 5:
        return Yavne
      case 6:
        return Oryeuda
      case 8:
        return Badabum
      case 10:
        return Jerusalem
      default:
        return Big
    }
  }

  render() {
    const draftOrder = JSON.parse(localStorage.getItem('orderly-new-order'))
    return (
      <>
        <Header user={this.state.user} />
        <Row>
          <Col lg={3}>
            <StickyBox offsetTop={32} offsetBottom={32}>
              <Location
                id={this.state.location._id}
                name={this.state.location.name || 'שם הסניף'}
                image={this.state.location._id && this.getImageById(this.state.location._id)}
                address={this.state.location.address || 'כתובת'}
                expanses={this.state.location.orders.total || 0}
                orders={this.state.location.orders.amount || 0}
                minified />
            </StickyBox>

          </Col>
          <Col lg={9}>
            {
              !this.state.isLoading ? (

                (this.state.location.orders.items && this.state.location.orders.items.length) ? (
                  <div id="orders">
                    {(draftOrder && draftOrder.amount > 0) && (
                      <>

                        <div className='upcoming-orders-wrapper'>
                          <div className="pageTitle">
                            <h2 className="title">הזמנות שעדיין לא נשלחו</h2>
                          </div>
                          <div className='upcoming-orders'>

                            <div className='upcoming-order'>
                              <Link className='upcoming-order-inner' tabIndex='0' to='/orders/new'>
                                <div className='upcoming-order-border'>
                                  {/* <div className='company'><img src={Lyonwhite} alt='' /></div>
                                  <div className='title'>הזמנה מס. {0}</div> */}
                                  <div className=''>{draftOrder.amount} מוצרים</div>
                                  <div className=''>
                                    <Currency
                                      quantity={draftOrder.total * 1.17}
                                      currency="ILS"
                                      decimal="."
                                      group=","
                                    />
                                  </div>
                                  <div className='timestamp'>
                                    <Moment title={draftOrder.timestamp} fromNow>{draftOrder.timestamp}</Moment>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>

                        </div>

                      </>
                    )}

                    <div className="pageTitle">
                      <h2 className="title">הזמנות</h2>
                    </div>

                    {/* <div className='filterOrders'>
                <div className={`filter-option${!this.state.shipped ? ' active' : ''}`} >
                  <Link to='#'>הזמנות</Link>
                </div>
                <div className={`filter-option${this.state.shipped ? ' active' : ''}`}>
                  <Link to='#'>תעודות משלוח</Link>
                </div>
                <div className={`filter-option${this.state.shipped ? ' active' : ''}`}>
                  <Link to='#'>חשבוניות מס</Link>
                </div>
              </div> */}

                    <div className="table-container">
                      <div className="table-actions">
                        <div className="table-search">
                          {/* <select onChange={(e) => this.changeMonth(e.target.value)}>
                      <option value='all'>חודש</option>
                      <option value="1">ינואר</option>
                      <option value="2">פברואר</option>
                      <option value="3">מרץ</option>
                      <option value="4">אפריל</option>
                      <option value="5">מאי</option>
                      <option value="6">יוני</option>
                      <option value="7">יולי</option>
                      <option value="8">אוגוסט</option>
                      <option value="9">ספטמבר</option>
                      <option value="10">אוקטובר</option>
                      <option value="11">נובמבר</option>
                      <option value="12">דצמבר</option>
                    </select>
                    <select onChange={(e) => this.changeYear(e.target.value)}>
                      <option value='all'>שנה</option>
                      <option value='2019'>2019</option>
                      <option value='2020'>2020</option>
                    </select>
                    {this.state.user && this.state.user.role == "owner" && (
                      <select onChange={(e) => this.changeLocation(e.target.value)}>
                        <option value='all'>כל הסניפים</option>
                        {
                          this.state.locations && this.state.locations.map(location => {
                            return (<option value={location._id} key={location._id}>{location.name}</option>)
                          })
                        }
                      </select>
                    )}   */}

                          {/* {this.state.user && this.state.user.role == "location" && (
                      <div className="input-search">
                        <input
                          className="input-search"
                          type="text"
                          placeholder="חפש הזמנה"
                          onChange={e =>
                            this.setState({ filterStr: e.target.value })
                          }
                        />
                      </div>
                    )} */}

                        </div>

                        {this.state.user && this.state.user.role == "location" && (
                          <Link to="/orders/new">
                            <Button>הזמנה חדשה</Button>
                          </Link>
                        )}

                      </div>


                      <table className="table table-orders">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>כמות מוצרים</th>
                            <th>מתי נשלחה</th>
                            <th>סכום כולל מע״מ</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.location.orders.items
                            ? this.state.location.orders.items
                              // .filter(item => item._id.includes(this.state.filterStr))
                              .map((order, id) => (
                                <tr className={`order-${order.shipped ? 'approved' : 'pending'}`} key={id}>
                                  <td className="orderId">
                                    <Link to={`/order/${order._id}`}>הזמנה מס. {order._id}</Link>
                                    {
                                      (order.shipped) ? (
                                        <div className='status status-approved'>ההזמנה אושרה</div>
                                      ) : (
                                          <div className='status status-pending'>ממתינה לאישור מהספק</div>
                                        )
                                    }
                                  </td>
                                  <td className="client">
                                    {
                                      (order.shipped) ?
                                        (
                                          this.getAmount(order.delivered)
                                        ) :
                                        (
                                          this.countItems(order.products)
                                        )
                                    }
                                  </td>
                                  <td>
                                    <Moment title={new Date(order.timestamp)} format={'LL'}>
                                      {order.timestamp}
                                    </Moment>
                                  </td>
                                  <td>
                                    <strong>
                                      {
                                        (order.shipped) ?
                                          (
                                            <Currency
                                              quantity={this.getTotal(order.delivered) * 1.17}
                                              currency="ILS"
                                              decimal="."
                                              group=","
                                            />
                                          ) :
                                          (
                                            <Currency
                                              quantity={order.total * 1.17}
                                              currency="ILS"
                                              decimal="."
                                              group=","
                                            />
                                          )
                                      }
                                    </strong>
                                  </td>
                                  <td className="viewOrderWrapper">
                                    {/* <img src={Eye} alt='' /> צפה בהזמנה */}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      {this.state.isLoading ? <Loader /> : ""}
                    </div>
                  </div>
                ) : (
                    <div id="orders" className='no-orders'>
                      <div className='no-orders-inner'>
                        <h1>{this.state.user.name && `${this.state.user.name}, `}הויטרינה שלך נראית לנו קצת ריקה...</h1>
                        <p>זה זמן טוב להעמיס כמה קרואסונים!</p>
                        <div className='new-order-cta'>
                          <Link to="/orders/new">
                            <Button>להעמסה לחץ כאן</Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              ) : (
                  <Loader />
                )
            }
          </Col>
        </Row >
        <Survey show={this.state.showModal} user={this.state.user} />
      </>
    );
  }

}

export default Orders;
