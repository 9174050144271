import React from 'react'
import axios from 'axios'

import './new.scss'
import Header from '../../../components/Header/Header'

class ProductsNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  loadUser = () => {
    // understand who the user is
    axios
      .get("/api/user/me")
      .then(res => {
        let user = res.data.user
        console.log('well well', user)
        this.setState({ user: user }, () => {
        })
      })
      .catch(err => {
        console.log('user me', err.code);

      });
  }

  componentDidMount() {
    this.loadUser()
  }

  render() {
    return (
      <>
        <Header user={this.state.user} />
        <div>This is ProductsNew</div>
      </>
    )
  }
}

export default ProductsNew;
