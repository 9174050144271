import React from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'
import logo from '../../../src/assets/logo.svg'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { user: {} }
  }
  setHome = () => {
    switch (this.state.user.role) {
      case "location":
        return "/orders"
      case "supplier":
        return "/supplier"
      case "owner":
        return "/locations"
      default:
        return "/"
    }
  }
  componentWillReceiveProps() {
    this.setState({ user: this.props.user })
  }

  render() {
    return (
      <header id='main'>
        <div className='header'>
          <div className='logo'>
            <Link to={this.setHome}>
              <img src={logo} className='App-logo' alt='logo' />
            </Link>
          </div>
          <div className='search'>
            <input type='text' className='search' placeholder='חפש הזמנה לפי מספר' />
          </div>
          <div className='personal'>
            {/* <div className='date'>יום ו', 24 בדצמבר 2019</div> */}
            <div className='user'>
              <div className='avatar'>{this.state.user.email && this.state.user.email.slice(0, 2).toUpperCase()}</div>
              <div>
                <div>{this.state.user.name}</div>
                <div className='logout'><a href='/logout'>התנתק</a></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
