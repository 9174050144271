import React from 'react';
import Icon from '../../components/Icon/Icon';
import './Modal.scss';
import PropTypes from 'prop-types';
import Button from '../../components/Button/Button';

export default class Modal extends React.Component {
  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <>
        {/* state{JSON.stringify(this.state.show)}<br /> */}
        <div className="modal show">
          <div className="modal-container">
            <div className="modal-content">
              <div className="modal-header">
                {!this.props.disableDismiss && (
                  <div className="close-modal" onClick={this.onClose}>
                    <Icon kind="icon_times" />
                  </div>
                )}
              </div>
              <div
                className="modal-body"
                ref={(el) => (this.componentRef = el)}
              >
                {this.props.children}
              </div>
              {/* <div className='modal-footer'></div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  disableDismiss: PropTypes.bool,
};
